import 'ant-design-vue/lib/style/index.less' // antd core styles
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
import './components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles

// select 2
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import Vue from 'vue'
import VuePageTitle from 'vue-page-title'
import NProgress from 'vue-nprogress'
import VueLayers from 'vuelayers'
import BootstrapVue from 'bootstrap-vue'
import VeeValidate from 'vee-validate'

import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './localization'
import './antd'
import './registerServiceWorker'

// mocking api
import './services/axios/fakeApi'

// JQuery
import 'jquery/dist/jquery.min.js'
// Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'

// config
import { ventureNames, ventureName } from './config/venture_config'

// pusher and echo
import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: '6b0ea795ea208fb3b4f3',
  cluster: 'ap2',
  encrypted: true,
})

// select 2
Vue.component('v-select', vSelect)

Vue.use(BootstrapVue)
Vue.use(VueLayers)

Vue.use(NProgress)
Vue.use(VuePageTitle, {
  prefix: 'D-OMS | ',
  router,
})

Vue.directive('uppercase', {
  update: function (el) {
    el.value = el.value.toUpperCase()
  },
})

// pusher and echo
// const accessToken = store.get('accessToken')
// const accessToken = localStorage.getItem('accessToken')
//
// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: process.env.VUE_APP_WEBSOCKETS_KEY,
//   wsHost: process.env.VUE_APP_API_URL,
//   wssHost: process.env.VUE_APP_API_URL,
//   wsPort: 6001,
//   wssPort: 6001,
//   forceTLS: false,
//   disableStats: true,
//   enabledTransports: ['ws', 'wss'],
//   authEndpoint: process.env.VUE_APP_API_URL + '/broadcasting/auth',
//   auth: {
//     headers: {
//       Authorization: 'Bearer ' + accessToken,
//     },
//   },
// })

// Vee Validation
Vue.use(VeeValidate, {
  classes: true,
  events: 'input',
  classNames: {
    valid: 'is-valid',
    invalid: 'is-invalid',
  },
})

Vue.component('pagination', require('laravel-vue-pagination'))

// eslint-disable-next-line no-unused-vars
Vue.prototype.$localCurrency = require('./views/helper/js/localCurrency')
Vue.prototype.$months = require('./views/helper/js/months')
Vue.prototype.$years = require('./views/helper/js/years')

// Config
Vue.prototype.$ventureNames = ventureNames
Vue.prototype.$ventureName = ventureName

Vue.config.productionTip = false
const nprogress = new NProgress({ parent: 'body' })

new Vue({
  router,
  store,
  nprogress,
  i18n,
  render: h => h(App),
}).$mount('#app')
